import React from "react"

const IndexPage = () => {
  React.useEffect(() => {
    if(window){
     window.location.replace('https://redebertoni.com.br/');
    }
  }, [...(typeof window !== 'undefined' ? [window]:[])])
  return <div></div>
};

export default IndexPage
